import { useTakerAuth, useTrackerAuth } from "@today/api"
import { useCallback } from "react"

export function useTrackerFetcher() {
  const tracker = useTrackerAuth()
  return useCallback(
    <Resp>(url: string) =>
      tracker.client.get<Resp>(url).then((res) => res.data),
    [tracker]
  )
}

export function useTakerFetcher() {
  const taker = useTakerAuth()
  return useCallback(
    <Resp>(url: string) => taker.client.get<Resp>(url).then((res) => res.data),
    [taker]
  )
}
