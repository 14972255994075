import { Client, TemporaryOrder } from "@today/api/taker"
import { getDeliveryClassName } from "@today/lib"
import { Button } from "baseui/button"
import { Drawer, SIZE } from "baseui/drawer"
import { Spinner } from "baseui/spinner"
import useSWR from "swr"
import { useOnCloseOnce } from "../../utils"
import { useTakerAuth } from "@today/api"
import dayjs from "dayjs"
import { Input } from "baseui/input"
import { FormControl } from "baseui/form-control"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from "react-icons/fa"
import { getErrorMessage } from "@today/api/utils"
import { toaster } from "baseui/toast"

type TemporaryOrderDetailDrawerProps = {
  orderId?: string
  onClose(): void
}

type CleaningStep = {
  step: "waiting" | "cleaning" | "success" | "failed"
  text: string
}

export function TemporaryOrderDetailDrawer({
  orderId,
  onClose,
}: TemporaryOrderDetailDrawerProps) {
  const taker = useTakerAuth()

  const {
    data: order,
    mutate,
    error: errorOrder,
    isValidating: isValidatingOrder,
  } = useSWR<TemporaryOrder>(orderId && `/api/temporary-orders/${orderId}`)
  const {
    data: client,
    error: errorClient,
    isValidating: isValidatingClient,
  } = useSWR<Client>(order && `/api/clients/${order.clientId}`)
  const isLoadingOrder = (!order && !errorOrder) || isValidatingOrder
  const isLoadingClient = (!client && !errorClient) || isValidatingClient
  const isLoading = isLoadingOrder || isLoadingClient

  const [showSender, setShowSender] = useState(false)
  const [showReceiver, setShowReceiver] = useState(false)

  const [senderAddressCleaningResult, setSenderAddressCleaningResult] =
    useState<CleaningStep>({
      step: "waiting",
      text: "",
    })
  const [receiverAddressCleaningResult, setReceiverAddressCleaningResult] =
    useState<CleaningStep>({
      step: "waiting",
      text: "",
    })

  const cleanAddress = useCallback(
    async (
      address: string,
      postalCode: string,
      isSender: boolean,
      isInit = false
    ) => {
      const setter = isSender
        ? setSenderAddressCleaningResult
        : setReceiverAddressCleaningResult
      setter({
        step: "cleaning",
        text: "정제 중입니다.",
      })
      try {
        const { address: cleanedAddress } = await taker.cleanAddress(
          address,
          postalCode || undefined
        )
        setter({
          step: "success",
          text: `(${cleanedAddress.postalCode}) ${cleanedAddress.streetBaseAddress} ${cleanedAddress.streetDetailAddress}`,
        })
      } catch (err) {
        if (isInit) {
          isSender ? setShowSender(true) : setShowReceiver(true)
        }
        setter({
          step: "failed",
          text: getErrorMessage(err),
        })
      }
    },
    [taker]
  )

  const [senderAddress, setSenderAddress] = useState("")
  const [senderPostalCode, setSenderPostalCode] = useState("")
  const [receiverAddress, setReceiverAddress] = useState("")
  const [receiverPostalCode, setReceiverPostalCode] = useState("")

  const initialSenderAddress = useRef("")
  const initialSenderPostalCode = useRef("")
  const initialReceiverAddress = useRef("")
  const initialReceiverPostalCode = useRef("")

  const initialized = useRef(false)
  useEffect(() => {
    if (!order) return
    if (initialized.current) return
    initialized.current = true
    initialSenderAddress.current =
      order.fixedSender?.address || order.sender.rawAddress
    initialSenderPostalCode.current =
      order.fixedSender?.postalCode || order.sender.address.postalCode
    initialReceiverAddress.current =
      order.fixedReceiver?.address || order.receiver.rawAddress
    initialReceiverPostalCode.current =
      order.fixedReceiver?.postalCode || order.receiver.address.postalCode

    setSenderAddress(initialSenderAddress.current)
    setSenderPostalCode(initialSenderPostalCode.current)
    setReceiverAddress(initialReceiverAddress.current)
    setReceiverPostalCode(initialReceiverPostalCode.current)

    cleanAddress(
      initialSenderAddress.current,
      initialSenderPostalCode.current,
      true,
      true
    )
    cleanAddress(
      initialReceiverAddress.current,
      initialReceiverPostalCode.current,
      false,
      true
    )
  }, [cleanAddress, order])

  const [isRetrying, setRetrying] = useState(false)

  const retry = useCallback(async () => {
    if (!order) return
    setRetrying(true)
    const senderChanged = !(
      senderAddress === initialSenderAddress.current &&
      senderPostalCode === initialSenderPostalCode.current
    )
    const receiverChanged = !(
      receiverAddress === initialReceiverAddress.current &&
      receiverPostalCode === initialReceiverPostalCode.current
    )
    try {
      const newOrder = await taker.patchTemporaryOrder(order.orderId, {
        ...(senderChanged
          ? {
              senderAddress,
              senderPostalCode,
            }
          : {}),
        ...(receiverChanged
          ? {
              receiverAddress,
              receiverPostalCode,
            }
          : {}),
      })

      const res = await taker.retryTemporaryOrders([order.orderId])
      if (res.orders.length > 0) {
        mutate(newOrder)
        toaster.positive(<>성공적으로 물품 주문이 완료되었습니다.</>, {
          autoHideDuration: 8000,
        })
      } else {
        mutate()
        toaster.negative(
          <>
            <p>재등록에 실패하였습니다.</p>
            <p>({res.failedOrders[0].reason})</p>
          </>
        )
      }
    } catch (err) {
      toaster.negative(
        <>
          <p>에러가 발생하였습니다.</p>
          <p>{getErrorMessage(err)}</p>
        </>,
        { autoHideDuration: 8000 }
      )
    } finally {
      setRetrying(false)
    }
  }, [
    order,
    senderAddress,
    senderPostalCode,
    receiverAddress,
    receiverPostalCode,
    taker,
    mutate,
  ])
  const fields: {
    name: string
    value: ReactNode
  }[] = order
    ? [
        {
          name: "고객사",
          value: client?.name ?? "",
        },
        {
          name: "송장번호",
          value: order.invoiceNumber,
        },
        { name: "배송 ID (외부 송장 번호)", value: order.clientShippingId },
        {
          name: "UUID",
          value: order.orderId,
        },
        {
          name: "운송 서비스",
          value: getDeliveryClassName(order.deliveryClass),
        },
        { name: "받는이", value: order.receiver.name },
        { name: "받는이 연락처", value: order.receiver.phone },
        {
          name: "물품 목록",
          value: order.products.map((v) => v.name).join(", "),
        },
        ...(order.clientOrderId
          ? [
              {
                name: "고객 주문번호",
                value: order.clientOrderId,
              },
            ]
          : []),
        {
          name: "원접수 시각",
          value: dayjs(order.orderTime).format("YYYY-MM-DD HH:mm"),
        },
        {
          name: "접수 실패 시각",
          value: dayjs(order.failedTime).format("YYYY-MM-DD HH:mm"),
        },
        {
          name: "해결 시각",
          value: dayjs(order.fixedTime).format("YYYY-MM-DD HH:mm"),
        },
      ]
    : []

  const onCloseOnce = useOnCloseOnce(orderId, onClose)
  return (
    <>
      <Drawer isOpen={!!orderId} onClose={onCloseOnce} size={SIZE.auto}>
        <div className="flex w-[36rem] flex-col">
          <div className="mb-4 flex items-center gap-x-2">
            <div className="text-xl font-semibold">물품 정보</div>
          </div>
          {order && !isLoading ? (
            <div className="flex w-full flex-col gap-y-2">
              {fields.map(({ name, value }) => (
                <div className="flex whitespace-pre-line">
                  <div className="w-40 font-semibold">{name}</div>
                  <div className="flex-1">{value}</div>
                </div>
              ))}
              <hr className="my-5" />
              <div className="w-40 font-semibold">접수 실패 사유</div>
              <div className="rounded bg-gray-200 px-4 py-10">
                {order.failedReason}
              </div>
              {order.fixedTime ? (
                <>
                  <hr className="my-5" />
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">기존 보낸이 주소</div>
                    <div className="flex-1">
                      {`(${order.sender.address.postalCode})`}{" "}
                      {order.sender.rawAddress}
                    </div>
                  </div>
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">수정 보낸이 주소</div>
                    <div className="flex-1">
                      (
                      {order.fixedSender?.postalCode ||
                        order.sender.address.postalCode}
                      ) {order.fixedSender?.address || order.sender.rawAddress}
                    </div>
                  </div>
                  <div className="mt-1 flex whitespace-pre-line">
                    <div className="w-40 font-semibold">기존 받는이 주소</div>
                    <div className="flex-1">
                      {`(${order.receiver.address.postalCode})`}{" "}
                      {order.receiver.rawAddress}
                    </div>
                  </div>
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">수정 받는이 주소</div>
                    <div className="flex-1">
                      (
                      {order.fixedReceiver?.postalCode ||
                        order.receiver.address.postalCode}
                      ){" "}
                      {order.fixedReceiver?.address ||
                        order.receiver.rawAddress}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <hr className="my-5" />
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">기존 보낸이 주소</div>
                    <div className="flex-1">{order.sender.rawAddress}</div>
                  </div>
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">
                      기존 보낸이 우편번호
                    </div>
                    <div className="flex-1">
                      {order.sender.address.postalCode || "(입력하지 않음)"}
                    </div>
                  </div>
                  {showSender && (
                    <>
                      <FormControl label="보낸이 주소 수정">
                        <Input
                          value={senderAddress}
                          onChange={(event) => {
                            setSenderAddress(event.target.value)
                            setSenderAddressCleaningResult({
                              step: "waiting",
                              text: "입력이 끝나길 기다리는 중입니다.",
                            })
                          }}
                          onBlur={() => {
                            cleanAddress(senderAddress, senderPostalCode, true)
                          }}
                        />
                      </FormControl>
                      <FormControl label="보낸이 우편번호 (영문 주소 시 필수)">
                        <Input
                          value={senderPostalCode}
                          onChange={(event) => {
                            setSenderPostalCode(event.target.value)
                            setSenderAddressCleaningResult({
                              step: "waiting",
                              text: "입력이 끝나길 기다리는 중입니다.",
                            })
                          }}
                          onBlur={() => {
                            cleanAddress(senderAddress, senderPostalCode, true)
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                  {senderAddressCleaningResult.step === "waiting" &&
                  senderAddressCleaningResult.text ? (
                    <p>
                      <FaSpinner className="mb-[2px] inline-block animate-spin" />{" "}
                      {senderAddressCleaningResult.text}
                    </p>
                  ) : senderAddressCleaningResult.step === "cleaning" ? (
                    <p>
                      <FaSpinner className="mb-[2px] inline-block animate-spin" />{" "}
                      {senderAddressCleaningResult.text}
                    </p>
                  ) : senderAddressCleaningResult.step === "success" ? (
                    <p className="text-green-500">
                      <FaCheckCircle className="mb-[2px] inline-block" />{" "}
                      {senderAddressCleaningResult.text}
                    </p>
                  ) : senderAddressCleaningResult.step === "failed" ? (
                    <p className="text-red-500">
                      <FaExclamationCircle className="mb-[2px] inline-block" />{" "}
                      {senderAddressCleaningResult.text}
                    </p>
                  ) : null}
                  <hr className="my-5" />
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">기존 받는이 주소</div>
                    <div className="flex-1">{order.receiver.rawAddress}</div>
                  </div>
                  <div className="flex whitespace-pre-line">
                    <div className="w-40 font-semibold">
                      기존 받는이 우편번호
                    </div>
                    <div className="flex-1">
                      {order.receiver.address.postalCode || "(입력하지 않음)"}
                    </div>
                  </div>
                  {showReceiver && (
                    <>
                      <FormControl label="받는이 주소 수정">
                        <Input
                          disabled={isRetrying}
                          value={receiverAddress}
                          onChange={(event) => {
                            setReceiverAddress(event.target.value)
                            setReceiverAddressCleaningResult({
                              step: "waiting",
                              text: "입력이 끝나길 기다리는 중입니다.",
                            })
                          }}
                          onBlur={() => {
                            cleanAddress(
                              receiverAddress,
                              receiverPostalCode,
                              false
                            )
                          }}
                        />
                      </FormControl>
                      <FormControl label="받는이 우편번호 (영문 주소 시 필수)">
                        <Input
                          disabled={isRetrying}
                          value={receiverPostalCode}
                          onChange={(event) => {
                            setReceiverPostalCode(event.target.value)
                            setReceiverAddressCleaningResult({
                              step: "waiting",
                              text: "입력이 끝나길 기다리는 중입니다.",
                            })
                          }}
                          onBlur={() => {
                            cleanAddress(
                              receiverAddress,
                              receiverPostalCode,
                              false
                            )
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                  {receiverAddressCleaningResult.step === "waiting" &&
                  receiverAddressCleaningResult.text ? (
                    <p>
                      <FaSpinner className="mb-[2px] inline-block animate-spin" />{" "}
                      {receiverAddressCleaningResult.text}
                    </p>
                  ) : receiverAddressCleaningResult.step === "cleaning" ? (
                    <p>
                      <FaSpinner className="mb-[2px] inline-block animate-spin" />{" "}
                      {receiverAddressCleaningResult.text}
                    </p>
                  ) : receiverAddressCleaningResult.step === "success" ? (
                    <p className="text-green-500">
                      <FaCheckCircle className="mb-[2px] inline-block" />{" "}
                      {receiverAddressCleaningResult.text}
                    </p>
                  ) : receiverAddressCleaningResult.step === "failed" ? (
                    <p className="text-red-500">
                      <FaExclamationCircle className="mb-[2px] inline-block" />{" "}
                      {receiverAddressCleaningResult.text}
                    </p>
                  ) : null}
                  <hr className="my-5" />
                  <Button
                    onClick={() => retry()}
                    isLoading={isRetrying}
                    disabled={
                      senderAddressCleaningResult.step !== "success" ||
                      receiverAddressCleaningResult.step !== "success" ||
                      isRetrying
                    }
                  >
                    수정된 주소로 재접수 하기
                  </Button>
                </>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </Drawer>
    </>
  )
}
