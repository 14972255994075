import KeycloakAdminClient from "@keycloak/keycloak-admin-client"
import UserRepresentation from "@keycloak/keycloak-admin-client/lib/defs/userRepresentation"
import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation"

export class KeycloakAdmin {
  client: KeycloakAdminClient
  credentials: {
    grantType: "client_credentials"
    clientId: string
    clientSecret: string
    azp?: string
  }

  constructor(
    baseUrl: string,
    realm: string,
    clientId: string,
    clientSecret: string,
    azp?: string
  ) {
    this.credentials = {
      grantType: "client_credentials" as const,
      clientId,
      clientSecret,
      azp,
    }
    this.client = new KeycloakAdminClient({
      baseUrl: baseUrl,
      realmName: realm,
    })
  }

  async createDriverUser(
    username: string,
    firstName: string,
    lastName: string,
    password: string,
    groups: string[],
    attributes?: Record<string, any>
  ): Promise<{ id: string }> {
    await this.client.auth(this.credentials)
    return this.client.users.create({
      username,
      firstName,
      lastName,
      groups,
      attributes,
      credentials: [
        {
          type: "password",
          value: password,
          temporary: false,
        },
      ],
      enabled: true,
    })
  }

  async updateDriverUser(
    id: string,
    username: string,
    firstName: string,
    lastName: string,
    password: string
  ): Promise<void> {
    await this.client.auth(this.credentials)
    const groups = await this.client.users.listGroups({ id })
    if (!groups.find((group) => group.path === "/today/deli")) {
      throw new Error("keycloak user is not belong to group /today/deli")
    }
    await this.client.users.update(
      { id },
      {
        username,
        firstName,
        lastName,
        ...(password
          ? {
              credentials: [
                {
                  type: "password",
                  value: password,
                  temporary: false,
                },
              ],
            }
          : {}),
        enabled: true,
      }
    )
  }

  async getUser(
    id: string
  ): Promise<
    | (Omit<UserRepresentation, "groups"> & { groups: GroupRepresentation[] })
    | undefined
  > {
    await this.client.auth(this.credentials)
    const [groups, users] = await Promise.all([
      this.client.users.listGroups({ id }),
      this.client.users.findOne({ id }),
    ])
    return { ...users, groups }
  }

  async getUserByUsername(username: string) {
    await this.client.auth(this.credentials)
    const users = await this.client.users.find({ username, exact: true })
    return users.length ? users[0] : undefined
  }

  async resetUserPassword(id: string, password: string) {
    await this.client.auth(this.credentials)
    await this.client.users.resetPassword({
      id,
      credential: {
        type: "password",
        value: password,
        temporary: false,
      },
    })
  }

  async createUser(userRequest: UserRepresentation) {
    await this.client.auth(this.credentials)
    return await this.client.users.create(userRequest)
  }

  async impersonateUser(id: string, clientId: string) {
    await this.client.auth(this.credentials)
    await this.client.users.update(
      { id },
      {
        attributes: {
          today_openapi_prod_client_id: [clientId],
          today_openapi_nonprod_client_id: [clientId],
        },
      }
    )
  }

  async updateUser(id: string, userRequest: UserRepresentation) {
    await this.client.auth(this.credentials)
    await this.client.users.update({ id }, userRequest)
  }

  async getUserTokenByPassword(
    username: string,
    password: string
  ): Promise<{ accessToken: string; refreshToken: string }> {
    await this.client.auth({
      username,
      password,
      grantType: "password",
      clientId: this.credentials.azp as string,
    })
    return {
      accessToken: (await this.client.getAccessToken()) || "",
      refreshToken: this.client.refreshToken || "",
    }
  }
}
