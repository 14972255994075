import { Billing } from "@today/api/taker"

export function getBillingTypeName(billingType: Billing["billingType"]) {
  switch (billingType) {
    case "PREPAID":
      return "충전식 결제"
    case "POSTPAID":
      return "후불 결제"
    case "INSTANT":
      return "현장 결제"
    case "TAX_INVOICE":
      return "세금계산서"
  }
}
