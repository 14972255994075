type IsUniqueTuple<T extends readonly any[]> = T extends [
  infer Head,
  ...infer Tail
]
  ? Head extends Tail[number]
    ? never
    : IsUniqueTuple<Tail>
  : T

type AllMembers<
  T extends string,
  AllMembersOfType extends readonly T[]
> = IsUniqueTuple<AllMembersOfType> extends never
  ? never
  : Exclude<AllMembersOfType[number], T> extends never
  ? Exclude<T, AllMembersOfType[number]> extends never
    ? readonly T[] & { length: AllMembersOfType["length"] }
    : never
  : never

/**
 * 주어진 string union type의 모든 멤버가 정확히 한 번 존재하는 array임을 컴파일 단계에서 확인한다.
 *
 * type State = "a" | "b" | "c";
 *
 * const pass1 = assertAllUnionMembers<State, ["a", "b", "c"]>("a", "b", "c")
 * const pass2 = assertAllUnionMembers<State, ["a", "c", "b"]>("a", "c", "b")
 * const pass3 = assertAllUnionMembers<State, ["a", "b", "c"]>("a", "c", "b")
 *
 * const invalid1 = assertAllUnionMembers<State, ["a", "b"]>("a", "b")                     // 오류: "c"가 없음
 * const invalid2 = assertAllUnionMembers<State, ["a", "b", "c", "d"]>("a", "b", "c", "d") // 오류: "d"는 State에 없음
 * const invalid3 = assertAllUnionMembers<State, ["a", "b", "d"]>("a", "b", "d")           // 오류: "c"가 없고 "d"는 State에 없음
 * const invalid4 = assertAllUnionMembers<State, ["a", "b", "c", "a"]>("a", "b", "c", "a") // 오류: "a"가 두 번 나옴
 *
 * const invalid5 = assertAllUnionMembers<State, ["a", "b"]>("a", "b", "c")                // 오류: "c"가 없음
 * const invalid6 = assertAllUnionMembers<State, ["a", "b", "c", "d"]>("a", "b", "c")      // 오류: "d"는 State에 없음
 * const invalid7 = assertAllUnionMembers<State, ["a", "b", "c", "a"]>("a", "b", "c")      // 오류: "a"가 두 번 나옴
 */
export function assertAllUnionMembers<
  T extends string,
  AllMembersOfUnionType extends readonly T[]
>(...allMembers: AllMembers<T, AllMembersOfUnionType>): readonly T[] {
  return allMembers
}
