import { ReactNode } from "react"
import { ROLE } from "../../utils"
import { Organization } from "@today/api/tracker"

type NavigationSection = {
  title: ReactNode
  items: {
    title: ReactNode
    url?: string
    requiredRole?: string
    externalLink?: boolean
    badge?: boolean | string
    activeOnSubPath?: boolean
  }[]
  // 해당 섹션을 표시할 수 있는 권한. 여러 개 중 하나라도 만족하면 표시한다.
  allowedRoles?: string[]
  isCollapsible?: boolean
}

export function getNavigationSections(roles: string[]): NavigationSection[] {
  const sections: NavigationSection[] = [
    {
      title: "대시보드",
      items: [
        {
          title: "홈",
          url: "/",
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "관제소",
          url: "/dashboard/map",
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "(구)지도 페이지",
          url: "https://old.portal.vtov.studio/dashboard",
          externalLink: true,
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "구역별 관제",
          url: "/dashboard/regions",
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "실시간 통계",
          url: "/dashboard/stats",
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "CJ 인천/부천",
          url: "/dashboard/cj",
          requiredRole: ROLE.cjDashboardViewer,
        },
      ],
      allowedRoles: [ROLE.cjDashboardViewer, ROLE.dashboardViewer],
    },
    {
      title: "물품/카트",
      items: [
        {
          title: "물품 리스트",
          url: "/loads",
          requiredRole: ROLE.loadList,
          badge: "NEW",
        },
        {
          title: "물품 검색",
          url: "/loads/search",
          requiredRole: ROLE.loadSearch,
        },
        {
          title: "등록 실패 물품",
          url: "/loads/temporary",
          requiredRole: ROLE.loadEditor,
        },
        {
          title: "카트 리스트",
          url: "/carts",
          requiredRole: ROLE.cartList,
        },
      ],
      allowedRoles: [
        ROLE.loadList,
        ROLE.loadSearch,
        ROLE.loadEditor,
        ROLE.cartList,
      ],
    },
    {
      title: "SME",
      items: [
        {
          title: "집화 정보",
          url: "/sme/pickup",
          requiredRole: ROLE.dashboardViewer,
        },
        {
          title: "파트너 공지",
          url: "/sme/notice",
          requiredRole: ROLE.dashboardViewer,
        },
      ],
      allowedRoles: [ROLE.dashboardViewer],
    },
    {
      title: "이슈",
      items: [
        {
          title: "이슈 리스트",
          url: "/issues",
          requiredRole: ROLE.issueList,
        },
      ],
      allowedRoles: [ROLE.issueList],
    },
    {
      title: "GAAS (beta)",
      items: [
        {
          title: "GAAS",
          url: "/gaas",
        },
      ],
    },
    {
      title: "알림톡",
      items: [
        {
          title: "알림톡 조건 리스트",
          url: "/alimtalks/conditions",
        },
        {
          title: "알림톡 발송 실패 리스트",
          url: "/alimtalks/send-faileds",
        },
        {
          title: "알림톡 템플릿 리스트",
          url: "/alimtalks/templates",
        },
      ],
      allowedRoles: [ROLE.alimtalkViewer],
    },
    {
      title: "고객사",
      items: [
        {
          title: "고객사 리스트",
          url: "/clients",
          requiredRole: ROLE.clientViewer,
        },
        {
          title: "고객사 정산",
          url: "/billings",
          requiredRole: ROLE.clientEditor,
          activeOnSubPath: true,
        },
        {
          title: "고객사 포인트",
          url: "/points",
          requiredRole: ROLE.clientEditor,
          activeOnSubPath: true,
        },
      ],
      allowedRoles: [ROLE.clientViewer],
    },
    {
      title: "외주협력사",
      items: [
        {
          title: "외주협력사 리스트",
          url: "/organizations",
          requiredRole: ROLE.admin,
        },
      ],
    },
    {
      title: "기사",
      items: [
        {
          title: "기사 운영 현황",
          url: "/drivers",
          requiredRole: ROLE.driverList,
          badge: true,
        },
        {
          title: "기사 운영 기록",
          url: "/driver-stats",
          requiredRole: ROLE.driverList,
        },
      ],
      allowedRoles: [ROLE.driverList],
    },
    {
      title: "트럭",
      items: [
        {
          title: "트럭 조회",
          url: "https://old.portal.vtov.studio/dashboard/trucks",
          externalLink: true,
        },
      ],
      allowedRoles: [ROLE.dashboardViewer],
    },
    {
      title: "분석",
      items: [
        {
          title: "운영",
          url: "/analytics/operation",
          requiredRole: ROLE.analyticsViewer,
        },
        {
          title: "영업",
          url: "/analytics/sales",
          requiredRole: ROLE.analyticsViewer,
        },
        {
          title: "재무",
          url: "/analytics/finance",
          requiredRole: ROLE.analyticsViewer,
        },
        {
          title: "알림톡 응답",
          url: "/analytics/notification-responses",
          requiredRole: ROLE.analyticsViewer,
        },
        {
          title: "A/B 테스트",
          url: "/analytics/abtest",
          requiredRole: ROLE.analyticsViewer,
        },
        {
          title: "My 광고 퍼포먼스",
          url: "/analytics/ads",
          requiredRole: ROLE.analyticsViewer,
        },
      ],
      allowedRoles: [ROLE.analyticsViewer, ROLE.operationAnalyticsViewer],
      isCollapsible: true,
    },
    {
      title: "유틸",
      items: [
        {
          title: "MQTT Playground",
          url: "/mqtt-playground",
        },
        {
          title: "단축 URL",
          url: "/shorten-urls",
        },
      ],
      allowedRoles: [ROLE.admin],
    },
    {
      title: "설정",
      items: [
        {
          title: "로그아웃",
          url: "/logout",
        },
      ],
    },
  ]
  return sections
    .filter((section) => {
      const { allowedRoles } = section
      return !(
        allowedRoles && !roles.find((role) => allowedRoles.includes(role))
      )
    })
    .map((section) => ({
      ...section,
      items: section.items.filter(
        ({ requiredRole }) => !requiredRole || roles.includes(requiredRole)
      ),
    }))
}

export function getOutsourcingOrganizationSection(
  organizations?: Organization[],
  clientRoles?: string[],
  outsourcingOrganizationId?: string
) {
  const outsourcingOrganizationSection = {
    title: "외주협력사 전용",
    items: clientRoles?.includes(ROLE.kdlogisticsViewer)
      ? [
          {
            title: "강동물류 시간미준수 물품 리스트",
            url: "/kangdong/delayed-loads",
            requiredRole: ROLE.kdlogisticsViewer,
          },
        ]
      : [],
    allowedRoles: [
      ROLE.hangangViewer,
      ROLE.seonyeongViewer,
      ROLE.kdlogisticsViewer,
      ROLE.outsourcingMaster,
    ],
    isCollapsible: true,
  }

  if (organizations) {
    // 외주 협력사 권한의 경우에는 해당 외주 협력사 페이지만 권한 부여
    if (
      clientRoles?.includes(ROLE.outsourcingMaster) &&
      outsourcingOrganizationId
    ) {
      const organization = organizations.find(
        (o) => o.id == outsourcingOrganizationId
      )

      if (organization) {
        outsourcingOrganizationSection.items =
          outsourcingOrganizationSection.items.concat([
            {
              title: `${organization.name} 물품 리스트`,
              url: `/orgs/${organization.id}/loads`,
              requiredRole: ROLE.outsourcingMaster,
            },
            {
              title: `${organization.name} 기사 운영 현황`,
              url: `/orgs/${organization.id}/drivers`,
              requiredRole: ROLE.outsourcingMaster,
            },
            {
              title: `${organization.name} 기사 운영 기록`,
              url: `/orgs/${organization.id}/driver-stats`,
              requiredRole: ROLE.outsourcingMaster,
            },
            {
              title: `${organization.name} 이슈 리스트`,
              url: `/orgs/${organization.id}/issues`,
              requiredRole: ROLE.outsourcingMaster,
            },
          ])
      }
    }

    // keycloak vtov 그룹 혹은 keycloak > today > master 권한의 경우에는 모든 외주 협력사 페이지에 대한 권한 부여
    if (
      clientRoles?.includes(ROLE.admin) ||
      clientRoles?.includes(ROLE.todayMaster)
    ) {
      outsourcingOrganizationSection.items =
        outsourcingOrganizationSection.items.concat(
          organizations
            .map((organization) => [
              {
                title: `${organization.name} 물품 리스트`,
                url: `/orgs/${organization.id}/loads`,
                requiredRole: ROLE.outsourcingMaster,
              },
              {
                title: `${organization.name} 기사 운영 현황`,
                url: `/orgs/${organization.id}/drivers`,
                requiredRole: ROLE.outsourcingMaster,
              },
              {
                title: `${organization.name} 기사 운영 기록`,
                url: `/orgs/${organization.id}/driver-stats`,
                requiredRole: ROLE.outsourcingMaster,
              },
              {
                title: `${organization.name} 이슈 리스트`,
                url: `/orgs/${organization.id}/issues`,
                requiredRole: ROLE.outsourcingMaster,
              },
            ])
            .flat()
        )
    }
  }

  return outsourcingOrganizationSection
}
