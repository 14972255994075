import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"

export class UrlRecordClient {
  client: AxiosInstance

  constructor(baseUrl: string, token?: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseUrl,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      })
    )
  }

  async getUrlRecords(offset: number, limit: number) {
    return this.client.get(
      `/url-records/?page_token=${offset}&page_size=${limit}`
    )
  }

  async shortenUrl(url: string, key: string) {
    if (key) {
      return this.client.post("/s/", { url, key })
    }
    return this.client.post("/s/", { url })
  }
}
