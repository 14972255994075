import { Client, ShippingPlace, TemporaryOrder } from "@today/api/taker"
import { formatInvoiceNumber } from "@today/lib"
import { StyledLink } from "baseui/link"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import Link from "next/link"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
} from "react-icons/fa"
import useSWR from "swr"
import dayjs from "dayjs"

type LoadTableProps = {
  orders?: TemporaryOrder[]
  isLoading?: boolean
}

const SORT_KEYS = {
  fixed: "FIXED",
  clientId: "CLIENT_ID",
  fixedTime: "FIXED_TIME",
}

export function TemporaryOrderTable({ orders, isLoading }: LoadTableProps) {
  const router = useRouter()
  const [sortColumn, setSortColumn] = useState<string>()
  const [sortAsc, setSortAsc] = useState(true)
  const sortedOrders = useMemo(() => {
    if (!orders) return undefined
    if (!sortColumn) return orders
    return [...orders].sort((a: TemporaryOrder, b: TemporaryOrder) => {
      const left = sortAsc ? a : b
      const right = sortAsc ? b : a
      const leftValue = getSortKey(left, sortColumn)
      const rightValue = getSortKey(right, sortColumn)
      return leftValue.localeCompare(rightValue, "en", {
        numeric: true,
        sensitivity: "base",
      })
    })
  }, [orders, sortColumn, sortAsc])
  const handleSort = (id: string) => {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc)
    } else {
      setSortColumn(id)
      setSortAsc(true)
    }
  }

  return (
    <TableBuilder
      data={sortedOrders}
      sortColumn={sortColumn}
      sortOrder={sortAsc ? "ASC" : "DESC"}
      onSort={handleSort}
      isLoading={isLoading ?? !orders}
      emptyMessage={
        <div className="mt-10 text-center">
          <p className="text-7xl">👻</p>
          <p className="mt-5 text-3xl">모두 해결 되었습니다.</p>
        </div>
      }
      overrides={{
        Root: {
          style: {
            maxHeight: "100%",
          },
        },
        TableHeadCellSortable: { style: { textAlign: "center" } },
        TableHeadCell: { style: { textAlign: "center" } },
        TableBodyCell: { style: { textAlign: "center" } },
      }}
    >
      <TableBuilderColumn
        header="해결"
        id={SORT_KEYS.fixed}
        sortable
        overrides={{
          TableHeadCellSortable: { style: { width: "1px" } },
          TableBodyCell: { style: { width: "1px" } },
        }}
      >
        {(order: TemporaryOrder) =>
          order.fixedTime ? (
            <FaCheckCircle className="mx-auto text-green-500" />
          ) : (
            <FaExclamationCircle className="mx-auto text-red-500" />
          )
        }
      </TableBuilderColumn>
      <TableBuilderColumn header="송장번호">
        {(order: TemporaryOrder) => (
          <Link
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                orderId: order.orderId,
              },
            }}
            passHref
          >
            <StyledLink>{formatInvoiceNumber(order.invoiceNumber)}</StyledLink>
          </Link>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="고객사 운송번호">
        {(order: TemporaryOrder) => (
          <Link
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                orderId: order.orderId,
              },
            }}
            passHref
          >
            <StyledLink>
              {formatInvoiceNumber(order.clientShippingId)}
            </StyledLink>
          </Link>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="고객사 주문번호">
        {(order: TemporaryOrder) => (
          <Link
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                orderId: order.orderId,
              },
            }}
            passHref
          >
            <StyledLink>{formatInvoiceNumber(order.clientOrderId)}</StyledLink>
          </Link>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="고객사" id={SORT_KEYS.clientId} sortable>
        {(order: TemporaryOrder) => <ClientName clientId={order.clientId} />}
      </TableBuilderColumn>
      <TableBuilderColumn header="보낸이 주소">
        {(order: TemporaryOrder) => (
          <>
            {order.failedReason.includes("sender.address") ? (
              <FaExclamationTriangle className="mr-1 inline-block text-yellow-500" />
            ) : null}
            {order.sender.rawAddress}
          </>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="받는이 주소">
        {(order: TemporaryOrder) => (
          <>
            {order.failedReason.includes("receiver.address") ? (
              <FaExclamationTriangle className="mr-1 inline-block text-yellow-500" />
            ) : null}
            {order.receiver.rawAddress}
          </>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn header="해결 시각" id={SORT_KEYS.fixedTime} sortable>
        {(order: TemporaryOrder) =>
          order.fixedTime
            ? dayjs(order.fixedTime).format("YYYY-MM-DD HH:mm")
            : "-"
        }
      </TableBuilderColumn>
    </TableBuilder>
  )
}

function ClientName({ clientId }: { clientId: string }) {
  const { data } = useSWR<Client>(`/api/clients/${clientId}`)
  return <span>{data?.name ?? ""}</span>
}

function getSortKey(order: TemporaryOrder, columnId: string): string {
  switch (columnId) {
    case SORT_KEYS.fixed:
      return order.fixedTime ? "A" : "B"
    case SORT_KEYS.clientId:
      return order.clientId
    case SORT_KEYS.fixedTime:
      return order.fixedTime ?? ""
  }
  return ""
}

// 331 291
